import { ServiceFactory } from '@/services/ServiceFactory'
const TypesProductsService = ServiceFactory.get('TypesProductsService')

export default {
  name: 'ZbAddTypeProductModal',
  data () {
    return {
      postData: {
        typeProduct: '',
        category: 'product',
        description: ''
      }
    }
  },
  model: {
    prop: 'value',
    event: 'input'
  },
  props: {
    value: {
      default: false
    },
    typeId: {
      default: null
    }
  },
  watch: {
    value (newVal, oldVal) {
      try {
        if (newVal) {
          this.$refs.observer2.reset()
        }
      } catch (error) {}
    }
  },
  methods: {
    dialogEvent () {
      this.clear()
      this.$emit('input')
    },
    async getId () {
      if (typeof this.typeId === 'string') {
        try {
          const { typeProduct } = await TypesProductsService.getOne(this.typeId)
          this.postData.typeProduct = typeProduct.typeProduct
          this.postData.description = typeProduct.description
        } catch (e) {
          this.$notify({
            title: 'Error',
            type: 'error',
            text: e.msg
          })
        }
      }
    },
    async save () {
      if (typeof this.typeId === 'string') {
        try {
          await TypesProductsService.put(this.typeId, this.postData)
          this.$notify({
            title: 'Exito:',
            type: 'success',
            text: 'El Tipo de producto se modificó correctamente'
          })
          this.dialogEvent()
        } catch (error) {
          this.$notify({
            title: 'Error',
            type: 'error',
            text: error.msg
          })
        }
      } else {
        try {
          await TypesProductsService.post(this.postData)
          this.$notify({
            title: 'Exito:',
            type: 'success',
            text: 'El Tipo de producto se creó correctamente'
          })
          this.dialogEvent()
        } catch (error) {
          this.$notify({
            title: 'Error',
            type: 'error',
            text: error
          })
        }
      }
    },
    clear () {
      this.postData = {
        typeProduct: '',
        category: 'product',
        description: ''
      }
      this.$refs.observer2.reset()
    }
  }
}
