import ZbProductTicketsModal from './product-tickets-modal'
import { mapGetters } from 'vuex'
import { ServiceFactory } from '@/services/ServiceFactory'
const ProductService = ServiceFactory.get('ProductService')

export default {
  components: {
    ZbProductTicketsModal
  },
  name: 'ZbProductTicketsComponent',
  props: {
    category: String
  },
  data () {
    return {
      search: '',
      loadingTable: false,
      loading: false,
      loadingData: false,
      dialog: false,
      currentProduct: null,
      dialogDel: false,
      loadingAvailability: false,
      headers: [
        {
          text: this.$t('productTickets.image'),
          value: 'image',
          sortable: false
        },
        {
          text: this.$t(`productTickets.${this.category}`),
          align: 'left',
          sortable: false,
          value: 'name'
        },
        { text: this.$t('productTickets.price'), value: 'price' },
        { text: this.$t('productTickets.description'), value: 'description' },
        { text: this.$t('productTickets.typeProd'), value: 'typeProd' },
        { text: this.$t('productTickets.available'), value: 'available' },
        // { text: this.$t('productTickets.availableForDiscount'), value: 'availableForDiscount', align: 'center' },
        { text: this.$t('productTickets.editDeleteAvailable'), value: 'action', sortable: false }
      ],
      products: [],
      menuUrl: null
    }
  },
  filters: {
    cutText: (value) => {
      if (!value) return ''
      value = value.toString()
      if (value.length > 120) {
        return `${value.substring(0, 120)}...`
      }
      return value
    }
  },
  mounted () {
    this.initialize()
  },
  computed: {
    ...mapGetters({
      authCredentials$: 'authModule/authCredentials'
    }),
    ...mapGetters('settingsModule', ['profileBarUrl$'])
  },
  methods: {
    initialize () {
      this.loadingData = true
      ProductService.getProducts(this.category)
        .then(resp => {
          this.products = resp.products
          this.loadingData = false
          this.menuUrl = resp.urlPath
        }).catch(() => {
          this.products = []
        })
    },
    editItem (item) {
      this.currentProduct = item
      this.dialog = true
    },
    openDeleteModal (item) {
      this.currentProduct = item
      this.dialogDel = true
    },
    closeDeleteModal () {
      this.currentProduct = null
      this.dialogDel = false
    },
    deleteItem () {
      this.loading = true
      ProductService.delete(this.currentProduct.zbProductId).then((resp) => {
        this.loading = false
        this.dialogDel = false
        this.$notify({
          type: 'success',
          text: resp.msg
        })
        this.initialize()
      }).catch((err) => {
        this.loading = false
        this.$notify({
          title: 'Error:',
          type: 'error',
          text: err.msg
        })
        this.dialogDel = false
      })
    },
    editAvailability (item) {
      this.loadingAvailability = true
      ProductService.putAvailability(item.zbProductId, { available: !item.available }).then((resp) => {
        this.loadingAvailability = false
        this.$notify({
          type: 'success',
          text: resp.msg
        })
        this.initialize()
      }).catch((err) => {
        this.loadingAvailability = false
        this.$notify({
          title: 'Error',
          type: 'error',
          text: err.msg
        })
      })
    },
    editDiscountAvailability (item) {
      this.loadingAvailability = true
      ProductService.putDiscountAvailability(item.zbProductId, { discount: !item.availableForDiscount }).then((resp) => {
        this.loadingAvailability = false
        this.$notify({
          type: 'success',
          text: resp.msg
        })
        this.initialize()
      }).catch((err) => {
        this.loadingAvailability = false
        this.$notify({
          title: 'Error',
          type: 'error',
          text: err.msg
        })
      })
    },
    openModal () {
      this.currentProduct = null
      this.dialog = true
    },
    closeModal () {
      this.currentProduct = null
      this.dialog = false
      this.initialize()
    },
    ticketsString (value) {
      if (value > 1) {
        return `${value} ${this.$t('productTickets.multipleTikets')}`
      } else if (value === 0) {
        return value
      }
      return `${value} ${this.$t('productTickets.singleTicket')}`
    }
  }
}
