import { required, minValue } from 'vuelidate/lib/validators'
import { ZbProductTicketsModel } from './ZbProductTicketsModel'
import { ServiceFactory } from '@/services/ServiceFactory'
const TypesProductsService = ServiceFactory.get('TypesProductsService')
const sizeValidation = (value, vm) => {
  if (!value) {
    return true
  }
  const file = value
  const mbValue = (file.size / (1024 * 1024)).toFixed(2)
  return (mbValue <= 2)
}

export default {
  data () {
    return {
      product: new ZbProductTicketsModel({}, this.category),
      listTypeProd: []
    }
  },
  created () {
    this.indexTypesProducts()
  },
  validations () {
    if (this.product.auxImage) {
      return {
        product: {
          name: { required },
          price: { required, minValue: minValue(1) },
          image: {},
          typeProd: { required },
          description: {}
        }
      }
    } else {
      return {
        product: {
          name: { required },
          price: { required, minValue: minValue(1) },
          image: { sizeValidation },
          typeProd: { required },
          description: {}
        }
      }
    }
  },
  computed: {
    nameErrors () {
      const errors = []
      if (!this.$v.product.name.$dirty) return errors
      !this.$v.product.name.required && errors.push(this.$t('validator.required', { field: this.$t('productTickets.name') }))
      return errors
    },
    priceErrors () {
      const errors = []
      if (!this.$v.product.price.$dirty) return errors
      !this.$v.product.price.required && errors.push(this.$t('validator.required', { field: this.$t('productTickets.price') }))
      !this.$v.product.price.minValue && errors.push(this.$t('validator.minValue', { field: 1 }))
      return errors
    },
    typeProdErrors () {
      const errors = []
      if (!this.$v.product.typeProd.$dirty) return errors
      !this.$v.product.typeProd.required && errors.push(this.$t('validator.required', { field: this.$t('productTickets.typeProd') }))
      return errors
    },
    imageErrors () {
      const errors = []
      if (!this.product.auxImage) {
        if (!this.$v.product.image.$dirty) return errors
        !this.$v.product.image.sizeValidation && errors.push(this.$t('validator.sizeValidation'))
      }
      return errors
    }
  },
  watch: {
    dialog (newVal) {
      if (newVal) {
        this.indexTypesProducts()
      }
    }
  },
  methods: {
    async indexTypesProducts () {
      const { success, typeProducts } = await TypesProductsService.get()
      if (success) {
        const typeProd = typeProducts.map((v) => {
          return {
            value: v.typeProduct,
            label: v.typeProduct
          }
        })
        this.listTypeProd = typeProd
      }
    },
    initData (param) {
      this.product = new ZbProductTicketsModel(param, this.category)
    },
    onFileChange (e) {
      const file = e
      this.product.previewFile = URL.createObjectURL(file)
    }
  }
}
