import ZbAddTypeProductModal from './zb-add-type-product-modal'
import { ServiceFactory } from '@/services/ServiceFactory'
const TypesProductsService = ServiceFactory.get('TypesProductsService')

export default {
  components: {
    ZbAddTypeProductModal
  },
  data () {
    return {
      search: '',
      loadingTable: false,
      typeProducts: [],
      typeIdIndex: null,
      showAddTypeProductModal: false,
      headers: [
        {
          text: this.$t('productTickets.typeProd'),
          value: 'typeProduct',
          sortable: false
        },
        {
          text: this.$t(`productTickets.description`),
          align: 'left',
          sortable: false,
          value: 'description'
        },
        { text: this.$t('productTickets.editDelete'), value: 'action', sortable: false }
      ]
    }
  },
  watch: {
    showAddTypeProductModal (newVal, oldVal) {
      if (newVal === undefined) {
        this.initialize()
      }
    }
  },
  mounted () {
    this.initialize()
  },
  methods: {
    async initialize () {
      try {
        const { success, typeProducts } = await TypesProductsService.get()
        if (success) {
          this.typeProducts = typeProducts
        }
      } catch (error) {}
    },
    openModal (id = null) {
      this.showAddTypeProductModal = true
      if (id) {
        this.typeIdIndex = id
      } else {
        this.typeIdIndex = null
      }
    },
    destroy (id) {
      this.$swal({
        title: this.$t('productTickets.deleteQuestion'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('delete.confirm'),
        cancelButtonText: this.$t('delete.cancel')
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            await TypesProductsService.delete(id)
            this.$swal(
              this.$t('delete.deleted'),
              this.$t('delete.successMessage'),
              'success'
            ).then(async (result) => {
              this.initialize()
            })
          } catch (error) {
            this.$notify({
              title: 'Error:',
              type: 'error',
              text: error.msg
            })
          }
        }
      })
    }
  }
}
