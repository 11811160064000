import { ServiceFactory } from '@/services/ServiceFactory'
import ZbProductTicketMixin from './ZbProductTicketMixin'
const ProductService = ServiceFactory.get('ProductService')

export default {
  data () {
    return {
      loading: false,
      max: 120,
      min: 0
    }
  },
  props: {
    dataProp: Object,
    category: String,
    dialog: Boolean
  },
  mixins: [ ZbProductTicketMixin ],
  watch: {
    dataProp: {
      handler: 'setProductData',
      immediate: true
    }
  },
  computed: {
    formTitle () {
      return this.dataProp ? this.$t('productTickets.editProduct', { A: this.$t(`productTickets.${this.category}`) }) : this.$t('productTickets.addNewProduct', { A: this.$t(`productTickets.${this.category}`) })
    },
    nameField () {
      if (this.category === 'product') {
        return 'productTickets.name'
      } else {
        return 'productTickets.nameService'
      }
    },
    typeField () {
      if (this.category === 'product') {
        return 'productTickets.typeProd'
      } else {
        return 'productTickets.typeServ'
      }
    }
  },
  methods: {
    setProductData () {
      if (this.dataProp) {
        this.initData(this.dataProp)
      } else {
        this.initData({})
      }
    },
    closeModal () {
      this.$emit('productModalData', false)
      this.loading = false
      this.$v.$reset()
      this.initData({})
    },
    async saveData () {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.loading = true
        const postData = {
          name: this.product.name,
          price: this.product.price,
          typeProd: this.product.typeProd,
          image: this.verifyImage(this.product.image, this.product.auxImage),
          description: this.product.description,
          category: this.product.category
        }
        if (this.product.zbProductId) {
          await ProductService.updateProductFormData(this.product.zbProductId, postData)
        } else {
          await ProductService.createProductFormData(postData)
        }
        this.closeModal()
      }
    },
    verifyImage (imageForm, previousImage) {
      if (!imageForm && previousImage) {
        return previousImage
      } else {
        return imageForm
      }
    }
  }
}
