import { mapGetters } from 'vuex'
import ZbProductTicketsComponent from './products'
import ZbTabTypeProducts from './tab-type-products'

export default {
  components: {
    ZbProductTicketsComponent,
    ZbTabTypeProducts
  },
  data () {
    return {
      tab: null
    }
  },
  computed: {
    ...mapGetters({
      authCredentials$: 'authModule/authCredentials'
    })
  }
}
