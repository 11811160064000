import _ from 'lodash'

export class ZbProductTicketsModel {
  constructor (obj, category) {
    this.zbProductId = obj.zbProductId || null
    this.name = obj.name || null
    this.price = obj.price || 0
    this.image = (_.isString(obj.image) ? null : obj.image) || null
    this.typeProd = obj.typeProd || null
    this.previewFile = obj.image || null
    this.auxImage = obj.image || null
    this.description = obj.description || ''
    this.category = category
  }
}
